.checkmark__container__success {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
}
.checkmark__circle__success {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  /* stroke: white; */
  fill: none;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}
.checkmark__success {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: #fff;
  stroke-miterlimit: 10;
  box-shadow: inset 0px 0px 0px #20ad41;
  animation:
    fillSuccess 0.4s ease-in-out 0.4s forwards,
    scaleSuccess 0.3s ease-in-out 0.9s both;
}
.checkmark__check__success {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: strokeSuccess 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@keyframes strokeSuccess {
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes scaleSuccess {
  0%,
  100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}
@keyframes fillSuccess {
  100% {
    box-shadow: inset 0px 0px 0px 50px #20ad41;
  }
}
