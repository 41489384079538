/* for view-cart section*/
.cartLoadingMain {
  position: fixed;
  z-index: 90;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100vw;
  height: 100v;
  background: rgba(0, 0, 0, 0.5);
}

.cartLoader {
  border: 8px solid #f3f3f3;
  border-top: 8px solid;
  border-radius: 50%;
  width: 110px;
  height: 110px;
  animation: spinloader 1.15s linear infinite;
  background-color: white;
  @apply shadow-md border-t-primary;
}

.cartLoader img {
  height: 120px;
  width: 120px;
}

@keyframes spinloader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.cartLoadingContainer {
  font-family: sans-serif;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 98vw;
  height: 100vh;
}
.dualRignSpinner {
  background-color: white;
  display: inline-block;
  position: relative;
  @apply rounded-full shadow-lg;
}
.dualRignSpinner:after {
  content: " ";
  display: block;
  width: 110px;
  height: 110px;
  margin: 6px;
  border-radius: 50%;
  border: 6px solid #092c56;
  border-color: #092c56 transparent #092c56 transparent;
  animation: dualRignSpinner 1.5s linear infinite;
}
@keyframes dualRignSpinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.cartLoadingImg {
  position: absolute;
  /* background-color: white; */
  @apply w-12 h-12;
}

/* for homepage load more section*/

.dualRignSpinnerHomepage {
  display: inline-block;
  position: relative;
  @apply rounded-full bg-gray-50 shadow-lg;
}
.dualRignSpinnerHomepage:after {
  content: " ";
  display: block;
  width: 40px;
  height: 40px;
  margin: 8px;
  border-radius: 50%;
  border: 2px solid #092c56;
  border-color: #092c56 transparent #092c56 transparent;
  animation: dualRignSpinner 1.5s linear infinite;
}

.cartLoadingImgHomePage {
  position: absolute;
  /* background-color: white; */
  @apply w-9 h-9;
}

/* for address view-cart section*/
.addressLoadingContainer {
  font-family: sans-serif;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  @apply py-3;
}

.dualRingSpinnerAddress {
  display: inline-block;
  position: relative;
  @apply rounded-full bg-gray-100 shadow-lg;
}
.dualRingSpinnerAddress:after {
  content: " ";
  display: block;
  width: 100px;
  height: 100px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #092c56;
  border-color: #092c56 transparent #092c56 transparent;
  animation: dualRingSpinnerAddress 1.5s linear infinite;
}
@keyframes dualRingSpinnerAddress {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.addressLogoImg {
  position: absolute;
  /* background-color: white; */
  @apply w-12 h-12;
}

/* for profile section*/
.profileLoadingContainer {
  font-family: sans-serif;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  @apply py-3;
}

.dualRingSpinnerProfile {
  display: inline-block;
  position: relative;
  @apply rounded-full bg-gray-100 shadow-lg;
}
.dualRingSpinnerProfile:after {
  content: " ";
  display: block;
  width: 80px;
  height: 80px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #092c56;
  border-color: #092c56 transparent #092c56 transparent;
  animation: dualRingSpinnerProfile 1.5s linear infinite;
}
@keyframes dualRingSpinnerProfile {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.profileLogoImg {
  position: absolute;
  /* background-color: white; */
  @apply w-8 h-8;
}

/* Fake Cart Icon*/
.loader-cart {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  display: inline-block;
  border-top: 3px solid #fff;
  border-right: 3px solid transparent;
  box-sizing: border-box;
  animation: rotation 0.65s linear infinite;
  @apply mt-0.5;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
